import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/leaveSchool',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/leaveSchool/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/leaveSchool',
    method: 'put',
    data
  })
}

export function findLeaveStuDataById(id) {
  return request({
    url: 'api/leaveSchool/'+id,
    method: 'get',
  })
}

export function downloadPdf(id) {
    return request({
        url: 'api/leaveSchool/pdf/'+id,
        method: 'get',
        responseType: 'blob'
    })
}

export default { add, edit, del,findLeaveStuDataById ,downloadPdf}
