<template>
  <div id="FamilyIndex">
    <!-- 查询 -->
    <van-dropdown-menu active-color="#23AB72">
      <van-dropdown-item v-model="query.teremYear" :options="options" @change="changeYear" />
    </van-dropdown-menu>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.teremYear +'年' +row.schoolTerem}}</span></span>
          </template>
          <template #label>
            <div class="custom-title  hidden-text95" style="color:black;margin:6px 0">
              {{row.className}}
            </div>
            <div class="custom-centent  hidden-text95">
              <svg-icon icon-class="bank-line" size="15" /> 院系: {{row.facultyName}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="bj" size="15" /> 专业: {{ row.specName}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="yhxx" size="15" /> 辅导员: {{ row.staffName }}
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.approvalStatus ==='0'" class="audit info-color b-color-info">待提交</span>
            <span v-else-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <div class="detail-pos" @click="toDetail(row)">
              <div class="text-center">
                <span class="num">
                  {{row.leaveSchoolStudents.length}}
                </span>
                <span style="color:#B2B2B2">人</span>
              </div>
              <div style="color:#B2B2B2">
                {{row.leaveSchoolName}}
              </div>
            </div>
            <div class="detail-pos2" @click="toDetail(row)"><van-icon name="arrow" /></div>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="stuDisableBtn(row.approvalStatus,'del')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" :disabled="stuDisableBtn(row.approvalStatus,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <switch-role @change="toQuery()"></switch-role>
    <!-- 提交 -->
    <div class="submit-button shadow-style">
      <van-button round block type="primary" @click="doAdd">新 增</van-button>
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import crudLeaveSchool from '@/api/modules/xj/leave'
import { mapGetters } from 'vuex'

export default {
  name: "FamilyIndex",
  mixins: [initData],
  dicts: ["xj_shzt", "xj_nj"],
  data() {
    return {
      showYearPicker: false,
      defaultIndex: 1,
      searchTetx: "",
      options: [],
    };
  },
  created() { },
  watch: {
    "dict.xj_nj": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.options = this.convertData(newval);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
      roles: 'roles',
      token: 'token'
    }),
  },
  methods: {
    beforeInit() {
      this.url = "api/leaveSchool";
      this.sort = ["approvalStatus,asc", 'facultyNo,desc'];
      // 设置审批字段
      this.spIdField = "approvalStatus"
      // 初始化条件
      this.beforeRefresh()
      return true;
    },
    beforeRefresh() {
      this.query.teremYear = new Date().getFullYear().toString();
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      if (roles.indexOf('自身') > -1) {
        this.query.approvalStatus = '0,2,3,4,9,201,401,208,408,301,308'
      }
      if (roles.indexOf('本级') > -1) {
        this.query.approvalStatus = '2,3,4,9,201,401,208,408,301,308'
      }
      if (roles.indexOf('全部') > -1) {
        let roleName = this.user.roles.map((item) => {
          return item.name
        })
        if (roleName.indexOf('超级管理员') === -1) {
          if (roleName.indexOf('离校审核单位负责人') > -1) {
            this.query.approvalStatus = '4,9,401,408'
          } else {
            // 学生处 只看审核完成的
            this.query.approvalStatus = '9'
          }
        }
      }
      return true
    },
    doAdd() {
      this.$router.push({ path: "/js/leave/school/form" });
    },
    toDetail(data) {
      this.$router.push({
        path: "/js/leave/school/detail",
        query: { id: data.id },
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/js/leave/school/form",
        query: { id: data.id },
      });
    },
    // 检索
    onSearch() {
      console.log("2321321");
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          crudLeaveSchool.del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.toQuery();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    onConfirm(value) {
      this.showYearPicker = false;
      this.query.teremYear = value.label;
      this.toQuery();
    },
    convertData(dict) {
      let options = [];
      dict.forEach((item) => {
        let data = { text: item.label + "年", value: item.value };
        options.push(data);
      });
      if (options) {
        this.query.teremYear = new Date().getFullYear().toString();
      }
      return options;
    },
    changeYear(val) {
      this.$nextTick((e) => {
        this.query.teremYear = val;
      });
      this.toQuery();
    },
    cleanSearch() {
      this.searchTetx = "";
      this.query = {};
      this.toQuery();
    },
    openShowHandler() {
      // 默认选中数据
      this.dict.xj_nj.forEach((item, index) => {
        if (this.query.teremYear) {
          if (this.query.teremYear === item.value) {
            this.defaultIndex = index;
          }
        } else if (new Date().getFullYear().toString() === item.value) {
          this.defaultIndex = index;
        }
      });
      this.showYearPicker = true;
    },
    stuDisableBtn(approvalStatus, type) {
      let status = parseInt(approvalStatus);
      if ([9].indexOf(status) > -1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang='scss' scoped>
@import '~@/assets/styles/student/family.scss';
.van-list {
  margin-bottom: 54px;
}
.van-cell__title {
  font-size: 16px;
  text-align: left;
  min-width: 70%;
}
::v-deep .van-dropdown-menu__bar {
  height: 44px;
  box-shadow: 0 0px 0px rgb(100 101 102 / 12%);
}
.detail-pos {
  position: absolute;
  top: 45%;
  right: 32px;
}
.detail-pos2 {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #b2b2b2;
}
#opt-style {
  .van-cell__title {
    min-width: 50%;
    font-size: 14px;
    line-height: 32px;
  }
}

.text-center {
  text-align: center;
  .num {
    color: #23ab72;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
